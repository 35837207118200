import { useState, useEffect } from 'react';
import { getPublicViewData } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';

const matomoTracking = (mtmUrl) => {
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = `${mtmUrl}`;
    s.parentNode.insertBefore(g, s);
};

export default function DynamicNotFoundPage() {
    // 404 does not support getServerSideProps, must fetch client side data
    // https://github.com/vercel/next.js/blob/master/errors/404-get-initial-props.md
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const pageData = await getPublicViewData('404');
            setData(pageData);
            const { mtmUrl } = pageData.componentProps.siteSetting;
            if (mtmUrl) {
                setTimeout(() => matomoTracking(mtmUrl), 100);
            }
            const { mtmUrlSecondary } = pageData.componentProps.siteSetting;
            if (mtmUrlSecondary) {
                setTimeout(() => matomoTracking(mtmUrlSecondary), 100);
            }
        }
        fetchData();
    }, []);

    if (!data) {
        return null;
    }

    return <NotFoundPage {...data} />;
}

function NotFoundPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];

    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return <Component {...componentProps} />;
}
